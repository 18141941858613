(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/create-game/assets/javascripts/draw-selection.js') >= 0) return;  svs.modules.push('/components/marketplace/create-game/assets/javascripts/draw-selection.js');
"use strict";


const {
  useCallback,
  useState,
  useEffect,
  useRef
} = React;
const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  useParams
} = ReactRouterDOM;
const {
  DropdownSelectItem,
  DropdownItem,
  DropdownLabel
} = svs.ui.ReactDropdown;
const {
  formatDate
} = svs.components.lbUtils.helpers.formatDate;
const {
  getDraw
} = svs.components.marketplaceData.selectors.draws;
const {
  selectDraw
} = svs.components.marketplaceData.createGame.actions;
const {
  getDrawItems
} = svs.components.marketplaceData.createGame.helpers;
const {
  resolveProductId
} = svs.utils.products;
const {
  JackPotAndFunds
} = svs.components.lbUi.jackpotAndFunds.JackPotAndFunds;
const {
  getProductItems
} = svs.components.marketplaceData.createGame.helpers;
const DrawItemInfo = _ref => {
  let {
    item,
    isEdit,
    className = ''
  } = _ref;
  if (isEdit) {
    return React.createElement("div", {
      className: "f-200 select-draw-line-height ".concat(className).trim()
    }, React.createElement("div", {
      className: "draw-selection-item-name qa-draw-selection-item-name"
    }, "".concat(item.name, " Spelstopp ").concat(formatDate(new Date(item.regCloseTime), true, true, false))));
  }
  return React.createElement("div", {
    className: "f-200 select-draw-line-height ".concat(className).trim()
  }, React.createElement("div", {
    className: "f-medium f-uppercase draw-selection-item-name qa-draw-selection-item-name"
  }, item.name), React.createElement("div", {
    className: "f-normal overflow-threedots"
  }, "Spelstopp ".concat(formatDate(new Date(item.regCloseTime), true, true, false))));
};
const DrawSelection = () => {
  const dispatch = useDispatch();
  const {
    activatedDrawId
  } = useParams();
  const [productItems, setProductItems] = useState();
  const prevProductItemsRef = useRef();
  const {
    draws
  } = useSelector(state => state.Draws);
  const {
    isSelectedDrawClosed,
    selectedDraw,
    selectedProduct
  } = useSelector(state => state.CreateGame || {});
  const draw = useSelector(state => getDraw(state, {
    drawNumber: selectedDraw,
    productName: selectedProduct
  }));
  const onSelectDraw = useCallback(drawNumber => {
    const drawItems = getDrawItems(draws[selectedProduct]);
    const isDrawDisabled = drawItems.some(_ref2 => {
      let {
        item,
        isDisabled
      } = _ref2;
      return drawNumber === item.drawNumber && isDisabled;
    });
    if (!isDrawDisabled) {
      dispatch(selectDraw(drawNumber));
    }
  }, [draws, dispatch, selectedProduct]);
  useEffect(() => {
    const fetchProductItems = async () => {
      const currentProductItems = await getProductItems({
        allProductNames: [selectedProduct],
        drawsByProduct: draws
      });
      if (!prevProductItemsRef.current || JSON.stringify(prevProductItemsRef.current) !== JSON.stringify(currentProductItems)) {
        setProductItems(currentProductItems);
      }
      prevProductItemsRef.current = currentProductItems;
    };
    fetchProductItems();
  }, [selectedProduct, draws]);
  if (activatedDrawId) {
    return draw ? React.createElement(DrawItemInfo, {
      className: "margin-bottom-1",
      isEdit: true,
      item: draw
    }) : null;
  }
  const drawItems = getDrawItems(draws[selectedProduct]);
  const selectedDrawIndex = drawItems.findIndex(_ref3 => {
    let {
      item,
      isDisabled
    } = _ref3;
    return selectedDraw === item.drawNumber || !selectedDraw && !isDisabled;
  });
  if (selectedDrawIndex === -1) {
    return null;
  }
  const otherAvailableDrawItems = drawItems.filter((_ref4, index) => {
    let {
      isDisabled
    } = _ref4;
    return index !== selectedDrawIndex && !isDisabled;
  });
  if (otherAvailableDrawItems.length === 0) {
    return React.createElement(DrawItemInfo, {
      className: "padding-xs-top padding-xs-left-1 padding-xs-bottom",
      item: drawItems[selectedDrawIndex].item
    });
  }
  if (!productItems) {
    return null;
  }
  return React.createElement("div", {
    className: "control-group ".concat(isSelectedDrawClosed ? 'selected-draw-closed' : ''),
    "data-testid": "draw-selection"
  }, React.createElement(DropdownSelectItem, {
    className: "select-draw-list",
    onSelect: onSelectDraw,
    v2: true
  }, drawItems.map((_ref5, index) => {
    let {
      item,
      isDisabled,
      disabledReason
    } = _ref5;
    const {
      item: productName,
      jackpots = [],
      funds = []
    } = productItems[0];
    const productId = resolveProductId(productName);
    const hasJackpotOrFunds = jackpots.length > 0 || funds.length > 0;
    return React.createElement(DropdownItem, {
      disabled: isDisabled,
      key: "".concat(item.key, "_").concat(item.name, "_").concat(item.regCloseTime),
      name: item.drawNumber,
      selected: index === selectedDrawIndex
    }, React.createElement(DrawItemInfo, {
      item: item
    }), Boolean(isDisabled) && React.createElement(DropdownLabel, {
      right: true
    }, React.createElement("div", {
      className: "qa-draw-selection-disabled-reason"
    }, disabledReason)), hasJackpotOrFunds && React.createElement(DropdownLabel, {
      right: true
    }, React.createElement("div", {
      className: "draw-selection-jackpot-funds"
    }, React.createElement(JackPotAndFunds, {
      drawNumber: item.drawNumber,
      funds: funds,
      jackpots: jackpots,
      productId: productId
    }))));
  })));
};
setGlobal('svs.components.marketplace.createGame.DrawSelection', DrawSelection);

 })(window);