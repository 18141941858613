(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/create-game/assets/javascripts/product-selection-bottom-sheet.js') >= 0) return;  svs.modules.push('/components/marketplace/create-game/assets/javascripts/product-selection-bottom-sheet.js');
'use strict';

const {
  useContext,
  useCallback,
  useEffect,
  useState,
  useRef
} = React;
const {
  useHistory,
  useParams
} = ReactRouterDOM;
const {
  useSelector
} = ReactRedux;
const {
  hooks
} = svs.components.marketplace.createGame;
const {
  getProductItems
} = svs.components.marketplaceData.createGame.helpers;
const {
  selectTeam
} = svs.components.marketplaceData.selectors.teams;
const {
  MarketplaceBrand,
  MarketplaceBrandContext
} = svs.components.marketplace.marketplaceBrand;
const {
  helpers
} = svs.components.marketplace;
const {
  resolveProductId
} = svs.utils.products;
const {
  ReactIcon,
  ReactButton: Button
} = svs.ui;
const {
  JackPotAndFunds
} = svs.components.lbUi.jackpotAndFunds.JackPotAndFunds;
const ProductSelectionBottomSheet = _ref => {
  let {
    onClose
  } = _ref;
  const history = useHistory();
  const {
    groupId
  } = useParams();
  const {
    getBrandedDisplayName
  } = useContext(MarketplaceBrandContext);
  const {
    availableProducts
  } = useSelector(state => state.CreateGame || {});
  const {
    draws
  } = hooks.useDraws({
    groupId
  });
  const [productItems, setProductItems] = useState();
  const prevProductItemsRef = useRef();
  const {
    id: teamId,
    name: teamName
  } = useSelector(state => selectTeam(state, {
    id: groupId
  }));
  const onProductClick = useCallback((productName, isDisabled) => {
    if (!isDisabled) {
      history.push({
        pathname: helpers.getTeamUrl(teamId, teamName, 'skapa-spel'),
        search: "?productName=".concat(productName),
        state: {
          internalLink: true
        }
      });
    }
  }, [history, teamId, teamName]);
  useEffect(() => {
    const fetchProductItems = async () => {
      const currentProductItems = await getProductItems({
        allProductNames: availableProducts,
        drawsByProduct: draws
      });
      if (!prevProductItemsRef.current || JSON.stringify(prevProductItemsRef.current) !== JSON.stringify(currentProductItems)) {
        setProductItems(currentProductItems);
      }
      prevProductItemsRef.current = currentProductItems;
    };
    fetchProductItems();
  }, [availableProducts, draws]);
  if (!productItems) {
    return null;
  }
  return React.createElement(svs.ui.ReactBottomSheet.BottomSheet, {
    behavior: "standard",
    className: "bottom-sheet-products qa-bottom-sheet-products",
    customer: "sport",
    isFixed: true,
    transparent: false
  }, React.createElement("div", {
    className: "bottom-sheet-products-header"
  }, React.createElement("div", {
    className: "title f-600 f-medium"
  }, "V\xE4lj spelomg\xE5ng"), React.createElement(Button, {
    className: "qa-back-button back-button icon-button",
    onClick: () => onClose(),
    type: "button"
  }, React.createElement(ReactIcon, {
    icon: "close",
    size: "300"
  }))), React.createElement("div", {
    className: "bottom-sheet-product-items-holder"
  }, productItems.map((productItem, index) => {
    const {
      item: productName,
      disabledReason,
      isDisabled,
      jackpots = [],
      funds = []
    } = productItem;
    const productId = resolveProductId(productName);
    const hasJackpotOrFunds = jackpots.length > 0 || funds.length > 0;
    const key = "".concat(productId, "_").concat(index);
    return React.createElement("div", {
      className: "bottom-sheet-product-item ".concat(isDisabled ? 'disabled' : ''),
      key: key,
      onClick: () => onProductClick(productName, isDisabled)
    }, React.createElement(MarketplaceBrand, {
      productId: productId,
      size: 100,
      square: true
    }), React.createElement("div", {
      className: "f-uppercase product-item-name f-600 f-medium"
    }, getBrandedDisplayName(productId)), Boolean(isDisabled) && React.createElement("div", {
      className: "product-item-disabled-reason"
    }, disabledReason), hasJackpotOrFunds && React.createElement("div", null, React.createElement(JackPotAndFunds, {
      funds: funds,
      jackpots: jackpots,
      productId: productId
    })));
  })));
};
setGlobal('svs.components.marketplace.createGame.ProductSelectionBottomSheet', ProductSelectionBottomSheet);

 })(window);