(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/create-game/assets/javascripts/helpers/bet-proposal-has-true.js') >= 0) return;  svs.modules.push('/components/marketplace/create-game/assets/javascripts/helpers/bet-proposal-has-true.js');

'use strict';

const betProposalHasTrue = betProposal => {
  let hasActiveRow = false;
  Object.keys(betProposal).forEach(rowKey => {
    Object.keys(betProposal[rowKey]).forEach(betKey => {
      if (betProposal[rowKey][betKey] === true) {
        hasActiveRow = true;
      }
    });
  });
  return hasActiveRow;
};
setGlobal('svs.components.marketplace.createGame.helpers.betProposalHasTrue', betProposalHasTrue);

 })(window);