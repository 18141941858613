(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/create-game/assets/javascripts/helpers/sort-draws.js') >= 0) return;  svs.modules.push('/components/marketplace/create-game/assets/javascripts/helpers/sort-draws.js');

'use strict';

const {
  products
} = svs.utils;
const {
  DRAWSTATE_OPEN,
  DRAWSTATE_DEFINED
} = svs.components.marketplaceData.constants;
const sortDraws = draws => Object.keys(draws).reduce((acc, key) => {
  if (!products.resolveProductId(key) && key !== 'HVS') {
    return acc;
  }
  acc[key] = draws[key].filter(_ref => {
    let {
      drawStateId
    } = _ref;
    return drawStateId === DRAWSTATE_OPEN || drawStateId === DRAWSTATE_DEFINED;
  }).sort((a, b) => new Date(a.regCloseTime).getTime() - new Date(b.regCloseTime).getTime());
  return acc;
}, {});
setGlobal('svs.components.marketplace.createGame.helpers.sortDraws', sortDraws);

 })(window);