(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/create-game/test-react/test-data.js') >= 0) return;  svs.modules.push('/components/marketplace/create-game/test-react/test-data.js');

'use strict';

const {
  productIds
} = svs.utils.products;
const DEFAULT_AVAILABLE_PRODUCTS = ['stryktipset', 'europatipset', 'topptipset'];
const groupId = 172561;
const containerId = 311875;
const activatedDrawId = 376612;
const testDates = Object.freeze({
  now: '2023-04-20T11:54:00.000Z',
  closingTopptipset: '2023-04-20T15:59:00.000Z',
  closingEuropatipset: '2023-04-21T13:59:00.000Z',
  buyStopStryktipset: '2023-04-22T13:29:00.000Z',
  closingStryktipset: '2023-04-22T13:59:00.000Z'
});
const testRoutes = Object.freeze({
  create: Object.freeze({
    path: '/lag/:groupId([0-9]+):teamPathName([-\\w]*)?/skapa-spel',
    url: "/lag/".concat(groupId, "-knacki/skapa-spel")
  }),
  edit: Object.freeze({
    path: '/lag/:groupId([0-9]+):teamPathName([-\\w]*)?/spel/:containerId([0-9]+)/:activatedDrawId([0-9]+)',
    url: "/lag/".concat(groupId, "-knacki/spel/").concat(containerId, "/").concat(activatedDrawId)
  })
});
const _getTestDraw = target => {
  const result = {
    stryktipset: {
      drawComment: 'Stryktipset v. 2023-16',
      drawNumber: 4805,
      drawState: 'Open',
      drawStateId: 2,
      name: 'V 16',
      productId: productIds.STRYKTIPSET,
      productName: 'stryktipset',
      regCloseTime: new Date(testDates.closingStryktipset)
    },
    europatipset: {
      drawComment: 'Europatipset v. 2023-16',
      drawNumber: 4395,
      drawState: 'Open',
      drawStateId: 2,
      name: 'V 16',
      productId: productIds.EUROPATIPSET,
      productName: 'europatipset',
      regCloseTime: new Date(testDates.closingEuropatipset)
    },
    topptipset: {
      drawComment: 'Topptipset v. 2023-16',
      drawNumber: 3473,
      drawState: 'Open',
      drawStateId: 2,
      name: 'Topp V 16',
      productId: 25,
      productName: 'topptipset',
      regCloseTime: new Date(testDates.closingTopptipset)
    },
    topptipsetExtra: {
      drawComment: 'Topptipset Extra',
      drawNumber: 3209,
      drawState: 'Open',
      drawStateId: 2,
      name: 'Europa V 16',
      productId: 24,
      productName: 'topptipsetextra',
      regCloseTime: new Date(testDates.closingEuropatipset)
    },
    topptipsetStryk: {
      drawComment: 'Topptipset Stryk',
      drawNumber: 1413,
      drawState: 'Open',
      drawStateId: 2,
      name: 'Stryk V 16',
      productId: 23,
      productName: 'topptipsetstryk',
      regCloseTime: new Date(testDates.closingStryktipset)
    }
  }[target];
  if (result) {
    return result;
  }
  throw new Error("invalid target ".concat(target));
};
const _getTestGame = () => ({
  activatedDrawId,
  buyStop: testDates.buyStopStryktipset,
  containerDescription: '',
  containerId,
  currentUserNumberOfShares: 1,
  description: '',
  drawCloseTime: testDates.closingStryktipset,
  drawNumber: _getTestDraw('stryktipset').drawNumber,
  fractionCommittedPrice: 0,
  fractionPrice: 123,
  fractions: {
    115906580: 1
  },
  fractionsCount: 1,
  groupId,
  groupName: 'Knacki',
  isAllowedToSubmit: false,
  isFinished: false,
  isSoldOut: false,
  isSubmittable: false,
  isSubmitted: false,
  maxFractions: 50,
  maxFractionsPerMember: 1,
  name: 'stryktipset v 16',
  preliminaryRowExists: false,
  productId: 1,
  productName: 'stryktipset',
  soldShares: 1,
  status: 'open',
  subProductId: null,
  templateId: 311875,
  totalBalance: 123,
  wagerSerial: null
});
const _getFakeState = function () {
  let {
    activatedDrawId = undefined,
    availableProducts = DEFAULT_AVAILABLE_PRODUCTS,
    selectedProduct = 'stryktipset'
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const draw = _getTestDraw(selectedProduct);
  const draws = {};
  if (availableProducts.includes(productIds.STRYKTIPSET)) {
    draws.stryktipset = [_getTestDraw('stryktipset')];
  }
  if (availableProducts.includes(productIds.EUROPATIPSET)) {
    draws.europatipset = [_getTestDraw('europatipset')];
  }
  if (availableProducts.includes(productIds.TOPPTIPSET)) {
    draws.topptipset = [_getTestDraw('topptipset'), _getTestDraw('topptipsetExtra'), _getTestDraw('topptipsetStryk')];
  }
  return {
    BetProposal: {},
    CreateGame: {
      activatedDrawId,
      availableProducts,
      buyStopDiff: 30,
      description: '',
      maxFractionsPerMember: 1,
      name: "".concat(draw.productName, " ").concat(draw.name).toLowerCase(),
      selectedDraw: draw.drawNumber,
      selectedProduct
    },
    Config: {
      buyStops: [{
        minutes: 5,
        label: '5 minuter före'
      }, {
        minutes: 30,
        label: '30 min. före'
      }, {
        minutes: 60,
        label: '1 tim. före'
      }]
    },
    Races: {},
    Draws: {
      draws,
      drawEvents: {},
      drawLoading: {}
    },
    Games: {
      [groupId]: {
        data: activatedDrawId ? [_getTestGame()] : []
      }
    },
    Members: {
      [groupId]: []
    },
    Meetings: {},
    CreateBetProposal: {}
  };
};
svs.components = svs.components || {};
svs.components.marketplace = svs.components.marketplace || {};
svs.components.marketplace.createGame = svs.components.marketplace.createGame || {};
svs.components.marketplace.createGame.test = svs.components.marketplace.createGame.test || {
  groupId,
  containerId,
  activatedDrawId,
  testDates,
  testRoutes,
  _getTestDraw,
  _getTestGame,
  _getFakeState
};

 })(window);