(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/create-game/assets/javascripts/advanced-options.js') >= 0) return;  svs.modules.push('/components/marketplace/create-game/assets/javascripts/advanced-options.js');
'use strict';

const {
  useContext
} = React;
const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  url
} = svs.utils;
const {
  resolveProductId
} = svs.utils.products;
const {
  InputNumber
} = svs.components.input_number;
const {
  Inputv2: Input
} = svs.ui.reactForm;
const {
  MAX_FRACTIONS_PER_GAME
} = svs.components.marketplaceData.createGame.constants;
const {
  createBetProposal
} = svs.components.marketplaceData;
const {
  changeBuyStopDiff
} = svs.components.marketplaceData.createGame.actions;
const {
  getDraw,
  getDrawEvents,
  selectDrawEventsErrorMessage
} = svs.components.marketplaceData.selectors.draws;
const {
  getGame
} = svs.components.marketplaceData.selectors.games;
const {
  selectCreateBetProposal
} = svs.components.marketplaceData.selectors.betProposal;
const {
  selectCreateGame
} = svs.components.marketplaceData.selectors.createGame;
const {
  BetProposalCreate
} = svs.components.marketplace.betProposal;
const {
  getRowId
} = svs.components.marketplace.helpers;
const {
  excludedProducts
} = svs.components.marketplace.betProposal;
const {
  BuyStopSelector
} = svs.components.marketplace.buyStopSelector;
const {
  EventListContainer
} = svs.components.marketplace.eventList;
const {
  MarketplaceBrandContext
} = svs.components.marketplace.marketplaceBrand;
const milliSecondsToMinutes = ms => Math.round(ms / 1000 / 60);
const {
  changeDescription
} = svs.components.marketplaceData.createGame.actions;
const AdvancedOptions = _ref => {
  let {
    groupId,
    activatedDrawId,
    isEditMode
  } = _ref;
  const dispatch = useDispatch();
  const {
    getBrandedDisplayName
  } = useContext(MarketplaceBrandContext);
  const drawEventsErrorMessage = useSelector(selectDrawEventsErrorMessage);
  const {
    selectedDraw: drawNumber,
    selectedProduct: productName,
    activatedDrawId: selectedActivatedDrawId,
    maxFractionsPerMember,
    maxFractionsPerGame,
    buyStopDiff: selectedBuyStopDiff
  } = useSelector(selectCreateGame);
  const {
    description
  } = useSelector(state => state.CreateGame);
  const selectedGame = useSelector(state => getGame(state, {
    activatedDrawId: selectedActivatedDrawId,
    groupId
  }) || {});
  const configBuystop = useSelector(state => state.Config.selectedBuyStop);
  const buyStopOptions = useSelector(state => state.Config.buyStops);
  const betProposalRowId = activatedDrawId || getRowId(productName, drawNumber, groupId);
  const {
    regCloseTime,
    raceId
  } = useSelector(state => getDraw(state, {
    drawNumber,
    productName
  }));
  const drawEvents = useSelector(state => getDrawEvents(state, {
    drawNumber,
    productName
  }));
  const betProposal = useSelector(state => selectCreateBetProposal(state, {
    activatedDrawId: betProposalRowId
  })) || {};
  const buyStopDiff = selectedBuyStopDiff || configBuystop;
  const minutesUntilDrawRegClose = milliSecondsToMinutes(regCloseTime - Date.now());
  const onMaxFractionsPerGameChange = amount => {
    dispatch(svs.components.marketplaceData.createGame.actions.changeMaxFractionsPerGame(amount));
  };
  let betProposalElement;
  if (drawEventsErrorMessage) {
    betProposalElement = React.createElement("span", {
      className: "f-bold block margin-bottom-half",
      "data-testid": "bet-proposal-error"
    }, drawEventsErrorMessage);
  } else if (productName && excludedProducts[url.encode(productName.toLowerCase())]) {
    betProposalElement = React.createElement(EventListContainer, {
      activatedDrawId: activatedDrawId,
      drawNumber: drawNumber,
      events: drawEvents || [],
      groupId: groupId,
      productName: productName,
      warningMessage: "Det g\xE5r inte att g\xF6ra n\xE5gon radskiss till ".concat(getBrandedDisplayName(resolveProductId(productName)), ".")
    });
  } else {
    betProposalElement = React.createElement("div", {
      className: "margin-top-2 margin-bottom-2"
    }, React.createElement("span", {
      className: "f-medium f-700 block margin-top-3 margin-bottom-1 f-center"
    }, "Radskiss"), React.createElement("p", {
      className: "f-400 f-center"
    }, "En id\xE9 om hur laget ska spela."), React.createElement(BetProposalCreate, {
      drawNumber: drawNumber,
      events: drawEvents || [],
      isDisabled: false,
      isLoading: isNaN(raceId) && !drawEvents || isNaN(raceId) && (drawEvents === null || drawEvents === void 0 ? void 0 : drawEvents.length) <= 0,
      onClearRow: () => {
        dispatch(createBetProposal.actions.clearRow(activatedDrawId, productName, drawNumber, groupId));
      },
      productName: productName,
      raceId: raceId,
      selectedOutcomes: betProposal,
      toggleOutcome: event => {
        dispatch(createBetProposal.actions.toggleOutcome(activatedDrawId, event, productName, drawNumber, groupId, event.participantNumber));
      }
    }));
  }
  return React.createElement("div", {
    "data-testid": "qa-advanced-options"
  }, React.createElement("div", {
    className: "f-medium f-700 margin-2 f-center"
  }, "Fler val"), React.createElement("div", {
    className: "control-group margin-top-2"
  }, React.createElement("div", {
    className: "control-group-description"
  }, "V\xE4lj max antal andelar per lagmedlem"), React.createElement(InputNumber, {
    className: "qa-max-fractions-per-member",
    focus: false,
    inverted: true,
    maxValue: Math.floor(maxFractionsPerGame / 2),
    minValue: selectedGame.maxFractionsPerMember || 1,
    onValueChange: amount => {
      dispatch(svs.components.marketplaceData.createGame.actions.changeMaxFractionsPerMember(amount));
    },
    size: 300,
    step: 1,
    transparent: false,
    value: maxFractionsPerMember
  })), React.createElement("div", {
    className: "control-group margin-top-2"
  }, React.createElement("div", {
    className: "control-group-description"
  }, "V\xE4lj max antal andelar per spelomg\xE5ng"), !isEditMode ? React.createElement(InputNumber, {
    className: "qa-max-fractions-per-game",
    focus: false,
    inverted: true,
    maxValue: MAX_FRACTIONS_PER_GAME,
    minValue: 1,
    onValueChange: onMaxFractionsPerGameChange,
    size: 300,
    step: 1,
    transparent: false,
    value: maxFractionsPerGame
  }) : maxFractionsPerGame), maxFractionsPerGame <= 1 && React.createElement("div", {
    className: "fc-red"
  }, "Det m\xE5ste alltid minst vara 2 andelar per spelomg\xE5ng"), React.createElement("div", {
    className: "control-group margin-top-2"
  }, React.createElement("div", {
    className: "control-group-description"
  }, "V\xE4lj tid f\xF6r k\xF6pstopp av andelar"), React.createElement("div", {
    className: "select-label"
  }, React.createElement(BuyStopSelector, {
    className: "qa-buystop-selector f-medium",
    drawCloseTime: regCloseTime,
    maxValue: minutesUntilDrawRegClose,
    onChange: newBuyStop => {
      dispatch(changeBuyStopDiff(newBuyStop));
    },
    options: buyStopOptions,
    value: buyStopDiff
  }))), betProposalElement, React.createElement("div", {
    className: "control-group input-v2-bg-white margin-bottom-2"
  }, React.createElement(Input, {
    className: "description-input qa-description",
    id: "create-game-input-description",
    label: "Meddelande",
    maxLength: 900,
    multiline: true,
    onChange: _ref2 => {
      let {
        target
      } = _ref2;
      return dispatch(changeDescription(target.value));
    },
    placeholder: "Kort beskrivning av spelet",
    value: description
  })), React.createElement("div", {
    className: "margin-bottom-2"
  }, React.createElement("div", {
    className: "f-bold control-group-description"
  }, "N\xE4r kan man l\xE4mna in spelet?"), React.createElement("div", null, "N\xE4r alla lagmedlemmar har k\xF6pt andelar kan lagkaptenen eller speladmin l\xE4mna in spelet. Den som startar spelet k\xF6per automatiskt en andel.")));
};
setGlobal('svs.components.marketplace.createGame.AdvancedOptions', AdvancedOptions);

 })(window);