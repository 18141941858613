(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/create-game/assets/javascripts/create-game.js') >= 0) return;  svs.modules.push('/components/marketplace/create-game/assets/javascripts/create-game.js');

'use strict';

const _excluded = ["teamPathName"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
const {
  useEffect,
  useState
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  useHistory
} = ReactRouterDOM;
const {
  DocumentTitle
} = svs.components.lbUtils;
const {
  CreateGameForm,
  DrawSelection,
  ProductSelectionDropdown
} = svs.components.marketplace.createGame;
const {
  GroupHeader
} = svs.components.marketplace.groupHeader;
const {
  hooks
} = svs.components.marketplace.createGame;
const {
  selectors,
  games,
  createGame
} = svs.components.marketplaceData;
const {
  selectTeam
} = svs.components.marketplaceData.selectors.teams;
const {
  roles
} = svs.components.marketplaceData.auth;
const {
  getColor
} = svs.components.marketplace.colorHelper;
const {
  loadFromLocalStorage
} = svs.components.marketplaceData.localStorage.actions;
const CreateGame = () => {
  const dispatch = useDispatch();
  const _ReactRouterDOM$usePa = ReactRouterDOM.useParams(),
    {
      teamPathName
    } = _ReactRouterDOM$usePa,
    params = _objectWithoutProperties(_ReactRouterDOM$usePa, _excluded);
  const activatedDrawId = parseInt(params.activatedDrawId, 10);
  const containerId = parseInt(params.containerId, 10);
  const groupId = parseInt(params.groupId, 10);
  const history = useHistory();
  const [isOpenProductSelector, setIsOpenProductSelector] = useState(true);
  const {
    errorMessage
  } = useSelector(state => state.CreateGame);
  const {
    redirectToGame,
    redirectToTeam
  } = hooks.useRedirectTo();
  const game = useSelector(state => selectors.games.getGame(state, {
    activatedDrawId,
    groupId
  }));
  const me = useSelector(state => selectors.members.selectMe(state, {
    groupId
  }));
  const team = useSelector(state => selectTeam(state, {
    id: groupId
  }));
  const isEditable = Boolean(game) && games.helpers.canMemberEditGame(me, game);
  const title = isEditable ? 'Ändra spel' : 'Skapa spel';
  useEffect(() => {
    if (Boolean(activatedDrawId) && errorMessage) {
      redirectToTeam({
        groupId,
        teamPathName,
        strategy: 'replace'
      });
    }
  }, [errorMessage, activatedDrawId, redirectToTeam, groupId, teamPathName]);
  useEffect(() => {
    if (!isEditable && activatedDrawId) {
      redirectToGame({
        activatedDrawId,
        containerId,
        groupId,
        strategy: 'push',
        teamPathName
      });
    }
  }, [activatedDrawId, containerId, groupId, isEditable, redirectToGame, teamPathName]);
  useEffect(() => {
    if (!activatedDrawId) {
      dispatch(createGame.actions.resetValues());
    }
  }, []);
  useEffect(() => {
    if (!activatedDrawId) {
      dispatch(loadFromLocalStorage());
    }
  }, []);
  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const preSelectedProduct = searchParams.get('productName');
    if (preSelectedProduct) {
      dispatch(createGame.actions.selectProduct(preSelectedProduct));
      setIsOpenProductSelector(false);
    }
  }, [dispatch, history.location.search]);
  const goBack = () => {
    var _history$location;
    if ((_history$location = history.location) !== null && _history$location !== void 0 && (_history$location = _history$location.state) !== null && _history$location !== void 0 && _history$location.internalLink) {
      history.goBack();
    } else {
      redirectToTeam({
        strategy: 'replace',
        groupId,
        teamPathName
      });
    }
  };
  return React.createElement("div", {
    className: "create-game-holder"
  }, React.createElement(DocumentTitle, {
    title: activatedDrawId ? 'Redigera spel' : 'Skapa spel'
  }), React.createElement(GroupHeader, {
    className: "team-list-heading",
    color: getColor(team.id),
    groupId: team.id || team.groupId,
    isCaptain: team.role === roles.ROLES.CAPTAIN,
    isInverted: false,
    membersCount: team.membersCount,
    myRole: team.role,
    onGoBack: () => goBack(),
    teamName: team.name
  }), React.createElement("div", {
    className: "single-column-view create-game"
  }, React.createElement("div", {
    className: "create-game-container qa-active-form"
  }, React.createElement("div", {
    className: "margin-bottom-1 margin-md-top-3"
  }, React.createElement("div", {
    className: "f-center create-game-container-product-info"
  }, React.createElement("div", {
    className: "create-game-container-title f-medium f-700 margin-bottom-1"
  }, title), React.createElement(ProductSelectionDropdown, {
    hasOpenProductOnLoad: isOpenProductSelector
  }), React.createElement(DrawSelection, null)), errorMessage && React.createElement("p", {
    className: "margin-top-1"
  }, errorMessage), React.createElement(CreateGameForm, {
    isEditMode: Boolean(activatedDrawId)
  }), React.createElement("div", {
    className: "padding-xs-bottom padding-md-bottom-2"
  })))));
};
setGlobal('svs.components.marketplace.createGame.CreateGame', CreateGame);

 })(window);