(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/create-game/assets/javascripts/helpers/redirect-button.js') >= 0) return;  svs.modules.push('/components/marketplace/create-game/assets/javascripts/helpers/redirect-button.js');

'use strict';

const {
  Button
} = svs.ui;
const {
  useParams
} = ReactRouterDOM;
const {
  useRedirectTo
} = svs.components.marketplace.createGame.hooks;
const RedirectButton = _ref => {
  let {
    className,
    label = 'Stäng'
  } = _ref;
  const {
    containerId,
    activatedDrawId,
    groupId,
    teamPathName
  } = useParams();
  const {
    redirectToGame,
    redirectToTeam
  } = useRedirectTo();
  return (
    React.createElement(Button, {
      block: true,
      className: className,
      onClick: () => {
        if (activatedDrawId) {
          redirectToGame({
            strategy: 'replace',
            activatedDrawId,
            containerId,
            groupId,
            teamPathName
          });
        } else {
          redirectToTeam({
            strategy: 'replace',
            groupId,
            teamPathName
          });
        }
      },
      size: 300,
      transparent: true,
      type: "button"
    }, label)
  );
};
setGlobal('svs.components.marketplace.createGame.helpers.RedirectButton', RedirectButton);

 })(window);