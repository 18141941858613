(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/create-game/assets/javascripts/hooks/use-redirect-to.js') >= 0) return;  svs.modules.push('/components/marketplace/create-game/assets/javascripts/hooks/use-redirect-to.js');

'use strict';

const {
  useHistory
} = ReactRouterDOM;
const {
  getTeamUrl
} = svs.components.marketplace.helpers;
const log = new svs.core.log.Logger('marketplace:client');
const useRedirectTo = () => {
  const history = useHistory();
  const validatedRedirect = (strategy, url, state) => {
    if (!history[strategy] && strategy) {
      log.info("Redirected with invalid strategy \"".concat(strategy, "\""));
      return history.push({
        pathname: url,
        state
      });
    }
    return history[strategy]({
      pathname: url,
      state
    });
  };
  const redirectToGame = function () {
    let {
      state,
      groupId,
      teamPathName,
      containerId,
      activatedDrawId,
      strategy = 'replace'
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    validatedRedirect(strategy, getTeamUrl(groupId, teamPathName.replace(/^-/, ''), 'spel', containerId, activatedDrawId), state);
  };
  const redirectToTeam = function () {
    let {
      groupId,
      teamPathName,
      strategy = 'replace'
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const teamUrl = getTeamUrl(groupId, teamPathName.replace(/^-/, ''));
    validatedRedirect(strategy, teamUrl);
  };
  return {
    redirectToGame,
    redirectToTeam
  };
};
setGlobal('svs.components.marketplace.createGame.hooks.useRedirectTo', useRedirectTo);

 })(window);