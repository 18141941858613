(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/create-game/assets/javascripts/product-selection-dropdown.js') >= 0) return;  svs.modules.push('/components/marketplace/create-game/assets/javascripts/product-selection-dropdown.js');
'use strict';

const {
  useContext,
  useCallback,
  useEffect,
  useState,
  useRef
} = React;
const {
  useParams
} = ReactRouterDOM;
const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  getProductItems
} = svs.components.marketplaceData.createGame.helpers;
const {
  selectProduct
} = svs.components.marketplaceData.createGame.actions;
const {
  MarketplaceBrand,
  MarketplaceBrandContext
} = svs.components.marketplace.marketplaceBrand;
const {
  resolveProductId
} = svs.utils.products;
const {
  getGame
} = svs.components.marketplaceData.selectors.games;
const {
  DropdownSelectItem,
  DropdownItem,
  DropdownLabel
} = svs.ui.ReactDropdown;
const {
  JackPotAndFunds
} = svs.components.lbUi.jackpotAndFunds.JackPotAndFunds;
const {
  showDialog
} = svs.components.marketplaceData.dialogWrapper.actions;
const ProductSelectionDropdown = _ref => {
  let {
    hasOpenProductOnLoad
  } = _ref;
  const dispatch = useDispatch();
  const {
    getBrandedDisplayName
  } = useContext(MarketplaceBrandContext);
  const {
    activatedDrawId,
    groupId
  } = useParams();
  const game = useSelector(state => activatedDrawId && getGame(state, {
    activatedDrawId,
    groupId
  }));
  const {
    availableProducts,
    selectedProduct,
    isSelectedDrawClosed
  } = useSelector(state => state.CreateGame || {});
  const {
    draws
  } = useSelector(state => state.Draws);
  const [productItems, setProductItems] = useState();
  const prevProductItemsRef = useRef();
  useEffect(() => {
    const fetchProductItems = async () => {
      const currentProductItems = await getProductItems({
        allProductNames: availableProducts,
        drawsByProduct: draws
      });
      if (!prevProductItemsRef.current || JSON.stringify(prevProductItemsRef.current) !== JSON.stringify(currentProductItems)) {
        setProductItems(currentProductItems);
      }
      prevProductItemsRef.current = currentProductItems;
    };
    fetchProductItems();
  }, [availableProducts, draws]);
  const onSelectProduct = useCallback(productName => {
    const productIsDisabled = productItems.some(_ref2 => {
      let {
        item,
        isDisabled
      } = _ref2;
      return productName === item && isDisabled;
    });
    const allClosed = productItems.every(_ref3 => {
      let {
        isDisabled
      } = _ref3;
      return isDisabled;
    });
    if (isSelectedDrawClosed && !allClosed) {
      dispatch(showDialog({
        title: 'Spelstopp är för nära',
        text: 'Spelstopp för omgången du valt är för nära. Välj en annan omgång.',
        icon: 'clock'
      }));
    }
    if (!productIsDisabled) {
      dispatch(selectProduct(productName));
    }
  }, [dispatch, isSelectedDrawClosed, productItems]);
  if (activatedDrawId) {
    if (!(game !== null && game !== void 0 && game.productId)) {
      return null;
    }
    return React.createElement("div", {
      className: "control-group product-selection-show",
      "data-testid": "product-selection-show"
    }, React.createElement(MarketplaceBrand, {
      className: "product-selection-show-product",
      productId: game.productId,
      size: 100,
      square: true
    }), React.createElement("div", {
      className: "f-medium f-500 margin-top-1"
    }, getBrandedDisplayName(game.productId)));
  }
  if (!productItems) {
    return null;
  }
  return React.createElement("div", {
    className: "control-group",
    "data-testid": "product-selection-choose"
  }, React.createElement(DropdownSelectItem, {
    className: "margin-bottom-1 select-product-list",
    isOpen: hasOpenProductOnLoad,
    onSelect: onSelectProduct,
    v2: true
  }, productItems.map((productItem, index) => {
    const {
      item: productName,
      disabledReason,
      isDisabled,
      jackpots = [],
      funds = []
    } = productItem;
    const productId = resolveProductId(productName);
    const hasJackpotOrFunds = jackpots.length > 0 || funds.length > 0;
    const key = "".concat(productId, "_").concat(index);
    return React.createElement(DropdownItem, {
      disabled: isDisabled,
      key: key,
      name: productName,
      selected: productName === selectedProduct
    }, React.createElement(MarketplaceBrand, {
      productId: productId,
      size: 100,
      square: true
    }), React.createElement(DropdownLabel, null, React.createElement("div", {
      className: "f-uppercase qa-product-selection-item-name"
    }, getBrandedDisplayName(productId))), Boolean(isDisabled) && React.createElement(DropdownLabel, {
      right: true
    }, React.createElement("div", {
      className: "qa-product-selection-disabled-reason"
    }, disabledReason)), hasJackpotOrFunds && React.createElement(DropdownLabel, {
      right: true
    }, React.createElement("div", {
      className: "product-selection-jackpot-funds"
    }, React.createElement(JackPotAndFunds, {
      funds: funds,
      jackpots: jackpots,
      productId: productId
    }))));
  })));
};
setGlobal('svs.components.marketplace.createGame.ProductSelectionDropdown', ProductSelectionDropdown);

 })(window);