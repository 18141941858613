(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/create-game/assets/javascripts/hooks/use-game-submit.js') >= 0) return;  svs.modules.push('/components/marketplace/create-game/assets/javascripts/hooks/use-game-submit.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  useParams
} = ReactRouterDOM;
const {
  form,
  format
} = svs.utils;
const {
  dialogWrapper
} = svs.components.marketplaceData;
const {
  useRedirectTo
} = svs.components.marketplace.createGame.hooks;
const {
  timelimitDialog
} = svs.components.marketplaceShared.specificDialogs.dialogs;
const {
  createGame,
  updateGame
} = svs.components.marketplaceData.createGame.actions;
const {
  clearFromLocalStorage
} = svs.components.marketplaceData.localStorage.actions;
const _updateGame = (dispatch, groupId, activatedDrawId, callback) => dispatch(updateGame(groupId, activatedDrawId, callback));
const _createGame = (dispatch, groupId, callback) => dispatch(createGame(groupId, callback));
const _clearFromLocalStorage = dispatch => dispatch(clearFromLocalStorage());
const handleNavigateToTransfer = () => {
  form.postToUrl(svs.core.urlMapping.get('transactionsHome'), {
    returnUrl: window.location.href
  });
};
const handleCheckAccountBalance = fractionPrice => new Promise((resolve, reject) => {
  svs.components.marketplaceData.createGame.services.fetchAccountBalance((error, balance) => {
    if (error) {
      return reject(new Error('balance-request-failed'));
    }
    if (balance < fractionPrice) {
      const err = new Error('saldo-low');
      err.balance = balance;
      return reject(err);
    }
    resolve();
  });
});

const handleCheckPlayerTimelimits = timeLimitData => {
  if (timeLimitData && (timeLimitData.day.remaining === '0' || timeLimitData.week.remaining === '0' || timeLimitData.month.remaining === '0')) {
    throw new Error('timelimit-exceeded');
  }
};

const handleShowTransferDialog = (dispatch, fractionPrice, balance) => {
  const msg = 'Du har inte tillräckligt med pengar på ditt spelkonto för att öppna spelet.';
  const text = "<p>Ditt saldo: ".concat(format.Currency(balance), " kr<br />Andelspris: ").concat(format.Currency(fractionPrice), " kr</p>");
  const buttons = [{
    text: 'Avbryt',
    classes: {
      inverted: true
    }
  }, {
    text: 'Sätt in',
    classes: {
      focus: true
    },
    callback: svs.components.marketplace.createGame.hooks.handleNavigateToTransfer
  }];
  dispatch(dialogWrapper.actions.showDialog({
    title: msg,
    text,
    buttons,
    icon: 'balance'
  }));
};
const useGameSubmit = function () {
  let {
    isEditMode = false
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    fractionPrice
  } = useSelector(state => state.CreateGame);
  const {
    containerId,
    activatedDrawId,
    groupId,
    teamPathName
  } = useParams();
  const dispatch = useDispatch();
  const {
    redirectToGame
  } = useRedirectTo();
  const updateOrCreate = () => {
    _clearFromLocalStorage(dispatch);
    const onUpdateComplete = error => {
      if (!error) {
        redirectToGame({
          strategy: 'push',
          containerId,
          activatedDrawId,
          groupId,
          teamPathName,
          state: {
            hasBackButton: true
          }
        });
      }
    };
    const onCreateComplete = (error, _ref) => {
      let {
        activatedDrawId: _activatedDrawId,
        containerId: _containerId
      } = _ref;
      if (!error) {
        setTimeout(() => {
          redirectToGame({
            strategy: 'replace',
            containerId: _containerId,
            activatedDrawId: _activatedDrawId,
            groupId,
            teamPathName,
            state: {
              isNewGame: true,
              hasBackButton: true
            }
          });
        }, 500);
      }
    };
    if (activatedDrawId) {
      svs.components.marketplace.createGame.hooks._updateGame(dispatch, groupId, activatedDrawId, onUpdateComplete);
    } else {
      svs.components.marketplace.createGame.hooks._createGame(dispatch, groupId, onCreateComplete);
    }
  };
  const handleSubmit = async function () {
    var _e$preventDefault;
    let e = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    (_e$preventDefault = e.preventDefault) === null || _e$preventDefault === void 0 || _e$preventDefault.call(e);
    if (isEditMode) {
      return updateOrCreate();
    }
    try {
      const canPlay = await svs.accountservices.player_exclusions.canPlayOnVertical('tipsspel');
      if (canPlay) {
        const timeLimitData = await svs.accountServices.player_timelimits.getPlayerTimeLimits();
        svs.components.marketplace.createGame.hooks.handleCheckPlayerTimelimits(timeLimitData);
        await svs.components.marketplace.createGame.hooks.handleCheckAccountBalance(fractionPrice);
        updateOrCreate();
      }
    } catch (err) {
      switch (err.message) {
        case 'timelimit-exceeded':
          dispatch(dialogWrapper.actions.showDialog(_objectSpread({}, timelimitDialog)));
          break;
        case 'balance-request-failed':
          dispatch(dialogWrapper.actions.showDialog({
            title: 'Det gick inte att hämta saldot.',
            type: 'error'
          }));
          break;
        case 'saldo-low':
          svs.components.marketplace.createGame.hooks.handleShowTransferDialog(dispatch, fractionPrice, err.balance);
          break;
        default:
          dispatch(dialogWrapper.actions.showDialog({
            title: 'Någinting gick fel, var god försök igen.',
            type: 'error'
          }));
      }
    }
  };
  return {
    handleSubmit
  };
};

setGlobal('svs.components.marketplace.createGame.hooks', {
  _updateGame,
  _createGame,
  handleShowTransferDialog,
  handleNavigateToTransfer,
  handleCheckAccountBalance,
  handleCheckPlayerTimelimits,
  useGameSubmit
});

 })(window);