(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/create-game/assets/javascripts/create-game-form.js') >= 0) return;  svs.modules.push('/components/marketplace/create-game/assets/javascripts/create-game-form.js');

'use strict';

const _excluded = ["teamPathName"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
const {
  useState,
  useEffect,
  useRef
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  useParams,
  useHistory
} = ReactRouterDOM;
const {
  Button,
  ReactIcon
} = svs.ui;
const {
  Inputv2: Input
} = svs.ui.reactForm;
const {
  ContentRevealReact: ContentReveal
} = svs.components;
const {
  Skeleton
} = svs.components.lbUi;
const {
  AdvancedOptions
} = svs.components.marketplace.createGame;
const {
  helpers
} = svs.components.marketplace.createGame;
const {
  actions
} = svs.components.marketplaceData.games;
const {
  betProposal: betProposalSelectors,
  games,
  form
} = svs.components.marketplaceData.selectors;
const {
  showDialog
} = svs.components.marketplaceData.dialogWrapper.actions;
const {
  actions: createGameActions,
  helpers: createGameHelpers
} = svs.components.marketplaceData.createGame;
const {
  hooks
} = svs.components.marketplace.createGame;
const {
  getRowId
} = svs.components.marketplace.helpers;
const {
  changeFractionPrice
} = svs.components.marketplaceData.createGame.actions;
const MIN_FRACTION_PRICE = 0;
const MAX_FRACTION_PRICE = 10001;
const MAX_RENDER_TIMEOUT_MS = 10 * 60 * 1000;
const CreateGameForm = _ref => {
  let {
    isEditMode,
    isLoading
  } = _ref;
  const dispatch = useDispatch();
  const nextRegCloseTime = useRef();
  const history = useHistory();
  const {
    redirectToTeam
  } = hooks.useRedirectTo();
  const _useParams = useParams(),
    {
      teamPathName
    } = _useParams,
    params = _objectWithoutProperties(_useParams, _excluded);
  const activatedDrawId = parseInt(params.activatedDrawId, 10);
  const containerId = parseInt(params.containerId, 10);
  const groupId = parseInt(params.groupId, 10);
  const {
    handleSubmit
  } = hooks.useGameSubmit({
    isEditMode
  });
  const {
    error: drawErrorMessage,
    DrawFetchState,
    draws,
    drawState,
    productDraws
  } = hooks.useDraws({
    activatedDrawId,
    containerId,
    groupId,
    forceFetch: true
  });
  const {
    selectedProduct,
    selectedDraw,
    fractionPrice,
    maxFractionsPerMember,
    buyStopDiff,
    isSelectedDrawClosed
  } = useSelector(state => state.CreateGame);
  const isPriceValid = fractionPrice > MIN_FRACTION_PRICE && fractionPrice < MAX_FRACTION_PRICE;
  const priceIsSet = fractionPrice !== undefined;
  const betProposalKey = activatedDrawId || getRowId(selectedProduct, selectedDraw, groupId);
  const isRemoving = useSelector(state => games.getIsRemoving(state, {
    activatedDrawId,
    groupId
  }));
  const hasFormChanged = useSelector(state => form.selectIsFormChanged(state, {
    activatedDrawId,
    groupId
  }));
  const betProposal = useSelector(state => betProposalSelectors.selectCreateBetProposal(state, {
    activatedDrawId: betProposalKey
  })) || {};
  const [advancedOptionVisiblity, toggleAdvancedOptions] = useState(() => {
    var _svs, _svs2;
    const DEFAULT_BUY_STOP = 0;
    const defaultBuyStopDiff = ((_svs = svs) === null || _svs === void 0 || (_svs = _svs.marketplace) === null || _svs === void 0 ? void 0 : _svs.data.initialState.Config.selectedBuyStop) || ((_svs2 = svs) === null || _svs2 === void 0 || (_svs2 = _svs2.marketplace_retail_admin) === null || _svs2 === void 0 ? void 0 : _svs2.data.initialState.Config.selectedBuyStop) || DEFAULT_BUY_STOP;
    return maxFractionsPerMember > 1 || buyStopDiff !== defaultBuyStopDiff || betProposal && helpers.betProposalHasTrue(betProposal) || false;
  });
  const disableButtons = isLoading || isRemoving;
  const dialogOptions = {
    title: 'Vill du verkligen radera spelet?',
    text: 'Alla insatser kommer att betalas tillbaka till lagets medlemmar.',
    icon: 'dustbin',
    buttons: [{
      text: 'Avbryt',
      classes: {
        inverted: true
      }
    }, {
      text: 'Radera',
      callback: async () => {
        dispatch(actions.removeGameAction(groupId, containerId, err => {
          if (!err) {
            redirectToTeam({
              strategy: 'replace',
              groupId,
              teamPathName
            });
          }
        }));
      }
    }]
  };
  const formatFractionPrice = value => {
    const _value = value.replace(/[^0-9]/g, '');
    return _value ? parseInt(_value, 10) : undefined;
  };
  const getButtonLabel = () => {
    if (isLoading) {
      return 'Sparar...';
    }
    if (isEditMode) {
      return 'Spara ändringar';
    }
    return 'Öppna för köp';
  };
  const handleOnRemoveButtonClick = () => {
    dispatch(showDialog(dialogOptions));
  };
  const onGoBack = () => {
    var _history$location;
    if ((_history$location = history.location) !== null && _history$location !== void 0 && (_history$location = _history$location.state) !== null && _history$location !== void 0 && _history$location.internalLink) {
      history.goBack();
    } else {
      redirectToTeam({
        groupId,
        teamPathName,
        strategy: 'replace'
      });
    }
  };
  useEffect(() => {
    const newNextRegCloseTime = createGameHelpers.getNextDrawCloseTime(productDraws);
    const now = new Date();
    const timeUntilClose = newNextRegCloseTime.getTime() - now.getTime();
    let renderTimeout;
    if (newNextRegCloseTime && timeUntilClose < MAX_RENDER_TIMEOUT_MS) {
      if (!newNextRegCloseTime || nextRegCloseTime.current && nextRegCloseTime.current.getTime() === newNextRegCloseTime.getTime()) {
        return;
      }
      renderTimeout = setTimeout(() => {
        nextRegCloseTime.current = undefined;
        const drawItems = createGameHelpers.getDrawItems(draws[selectedProduct]);
        const selectedDrawItem = drawItems.find(_ref2 => {
          let {
            item
          } = _ref2;
          return selectedDraw === item.drawNumber;
        });
        const newSelectedDrawClosed = selectedDrawItem && selectedDrawItem.isDisabled;
        if (!isSelectedDrawClosed && newSelectedDrawClosed) {
          dispatch(actions.selectedDrawClosed(selectedDraw));
        }
      }, timeUntilClose);
      nextRegCloseTime.current = newNextRegCloseTime;
    }
    return () => {
      clearTimeout(renderTimeout);
    };
  }, [dispatch, draws, isSelectedDrawClosed, productDraws, selectedDraw, selectedProduct]);
  useEffect(() => () => dispatch(createGameActions.clearFormAction), [dispatch]);
  const renderToggleButton = () => React.createElement(Button, {
    block: true,
    className: "qa-toggle-advanced-options margin-bottom-1 margin-top-2",
    onClick: () => toggleAdvancedOptions(prev => !prev),
    transparent: true,
    type: "button"
  }, advancedOptionVisiblity ? 'Färre val' : 'Fler val', React.createElement(ReactIcon, {
    class: "margin-left marketplace-rotate".concat(advancedOptionVisiblity ? ' marketplace-rotate-180' : ''),
    icon: "menu-down",
    size: "200"
  }));
  const renderErrorView = () => React.createElement("div", {
    className: "qa-no-open-draws",
    "data-testid": "no-open-draws"
  }, drawErrorMessage ? React.createElement("p", {
    className: "f-medium"
  }, drawErrorMessage) : React.createElement(React.Fragment, null, React.createElement("p", {
    className: "f-medium"
  }, isSelectedDrawClosed ? 'Den valda omgången är stängd. Det finns inga öppna omgångar just nu.' : 'Det finns inte några öppna spelomgångar för tillfället.'), React.createElement("p", null, "F\xF6rs\xF6k igen lite senare!")), React.createElement(helpers.RedirectButton, {
    className: "qa-cancel-button",
    label: "St\xE4ng"
  }));
  const renderLoadView = () => React.createElement("div", {
    className: "marketplace-full-height marketplace-center-container qa-loading-form-data"
  }, React.createElement("div", {
    className: "margin-half",
    style: {
      display: 'inline-flex',
      flexDirection: 'row'
    }
  }, React.createElement(Skeleton, {
    height: 4,
    numLines: 1,
    style: {
      width: '96px'
    }
  }), React.createElement("div", {
    className: "margin-left"
  }, React.createElement(Skeleton, {
    className: "margin-half",
    height: 1,
    numLines: 1,
    style: {
      width: '200px',
      maxHeight: '12px'
    }
  }), React.createElement(Skeleton, {
    className: "margin-half",
    height: 1,
    numLines: 1,
    style: {
      width: '150px',
      maxHeight: '12px'
    }
  }), React.createElement(Skeleton, {
    className: "margin-half",
    height: 1,
    numLines: 1,
    style: {
      width: '150px',
      maxHeight: '12px'
    }
  }))));
  if (drawState === DrawFetchState.ERROR) {
    return renderErrorView();
  }
  if (drawState === DrawFetchState.LOADING) {
    return renderLoadView();
  }
  return React.createElement("form", {
    className: "create-game-form",
    onSubmit: handleSubmit
  }, React.createElement("div", {
    className: "control-group fraction-price input-v2-bg-white margin-top-2"
  }, React.createElement(Input, {
    autoComplete: "off",
    className: "qa-fraction-price",
    "data-testid": "fraction-price",
    disabled: isEditMode,
    id: "create-game-input-price",
    label: "V\xE4lj pris per andel",
    onChange: _ref3 => {
      let {
        target
      } = _ref3;
      return dispatch(changeFractionPrice(formatFractionPrice(target.value)));
    },
    suffix: "kr",
    type: "tel",
    value: String(fractionPrice || '')
  }), !isPriceValid && priceIsSet && priceIsSet && React.createElement("div", {
    className: "error-message"
  }, "Insatsen f\xE5r inte vara h\xF6gre \xE4n 10 000 kr.")), !advancedOptionVisiblity && renderToggleButton(), React.createElement("div", {
    className: "create-game-form-content-holder"
  }, React.createElement(ContentReveal, {
    className: "create-game-form-content-reveal",
    show: advancedOptionVisiblity
  }, React.createElement(AdvancedOptions, {
    activatedDrawId: parseInt(activatedDrawId, 10),
    groupId: Number(groupId),
    isEditMode: isEditMode
  }), renderToggleButton()), isEditMode && React.createElement(React.Fragment, null, React.createElement(Button, {
    block: true,
    className: "qa-remove-button btn-icon-left delete-game-btn",
    "data-testid": "remove-button",
    disabled: disableButtons,
    onClick: handleOnRemoveButtonClick,
    size: 300,
    transparent: true,
    type: "button"
  }, React.createElement(ReactIcon, {
    icon: "dustbin"
  }), isRemoving ? 'Raderar...' : 'Radera spelet'), React.createElement("p", {
    className: "f-center fc-red margin-top-1"
  }, "Alla insatser betalas tillbaka om spelet raderas."))), React.createElement("div", {
    className: "crate-game-form-buttons margin-top-3"
  }, React.createElement(Button, {
    block: true,
    className: "margin-bottom-1 qa-submit-button",
    "data-testid": "submit-button",
    disabled: !isPriceValid || disableButtons || !hasFormChanged && isEditMode,
    focus: true,
    size: 300,
    type: "submit"
  }, getButtonLabel()), React.createElement(Button, {
    block: true,
    className: "margin-bottom-1 qa-cancel-button",
    onClick: () => onGoBack(),
    size: 300,
    transparent: true
  }, "Avbryt")));
};
setGlobal('svs.components.marketplace.createGame.CreateGameForm', CreateGameForm);

 })(window);